


























import { EAccountNotification, ERole } from '@/api/schema';
import NotificationPopupContentMixin from '@/components/notification-popup/NotificationPopupContentMixin';
import WelcomePopupRocket from '@/assets/images/notification/welcome-popup-rocket.svg?inline';
import WelcomePopupTitle from '@/assets/images/notification/welcome-popup-title.svg?inline';
import { mapMutations } from 'vuex';

export default {
  name: 'NotificationPopupWelcome',
  mixins: [NotificationPopupContentMixin],
  components: { WelcomePopupRocket, WelcomePopupTitle },
  data(): any {
    return {
      nextItem: EAccountNotification.INVITE_DEVELOPER,
      list: Object.freeze([
        'Invite a developer to do tech integration',
        'Customize the design of the loyalty program page according to your casino styles',
        'Execute marketing activities based on our guidelines',
        'Go Live'
      ])
    };
  },
  methods: {
    ...mapMutations('Onboarding', ['setInviteUserDialogOptions']),

    handleContinue(): void {
      window.dataLayer.push({
        event: 'welcome_popup_continue',
        eventCategory: 'onboarding',
        eventAction: 'click',
        eventLabel: 'welcome_popup_continue'
      });
      this.handleClose();

      setTimeout(() => {
        window.dataLayer.push({
          event: 'invite_developer_pop_up',
          eventCategory: 'onboarding',
          eventAction: 'sumbit',
          eventLabel: 'invite_developer_popup'
        });

        this.setInviteUserDialogOptions({
          show: true,
          type: ERole.ROLE_OPERATOR_DEVELOPER,
          title: 'Get Started 🏁',
          description:
            'Add your team members to strart integration of\n' +
            'Trueplay loyalty page.'
        });
      }, 300);
    }
  }
};
